import { fetchPriorities, fetchSystemPriorities, postPriorities } from 'app/api/masterplan';
import { IPrioritiesApiParams, IPrioritiesResult } from 'app/api/masterplan/types';
import { displaySuccess } from 'app/helpers/NotificationHelpers/helpers';
import { ICampaignPriority } from 'app/types/ICampaignPriority';
import { DispatchFn, GetStateFn } from 'app/types/state';

import * as actions from './actions';

const getPriorities = (params: IPrioritiesApiParams) => async (dispatch: DispatchFn) => {
  dispatch(actions.getPriorities());

  try {
    const result: IPrioritiesResult = await fetchPriorities(params);
    return dispatch(
      actions.getPrioritiesSuccess({
        ...result,
        systemPreview: false,
      }),
    );
  } catch (err) {
    return dispatch(actions.getPrioritiesError(err));
  }
};

const getSystemPriorities = (params: IPrioritiesApiParams) => async (dispatch: DispatchFn) => {
  dispatch(actions.getPriorities());
  try {
    const result: IPrioritiesResult = await fetchSystemPriorities(params);
    return dispatch(
      actions.getPrioritiesSuccess({
        ...result,
        systemPreview: true,
      }),
    );
  } catch (err) {
    return dispatch(actions.getPrioritiesError(err));
  }
};

// const lockMasterplan = () => async (dispatch: DispatchFn, getState: GetStateFn) => {
//   dispatch(actions.lockMasterplan());

//   const { masterplan } = getState();
//   try {
//     await save({ ...masterplan, locked: true });
//     dispatch(actions.lockMasterplanSuccess());
//     displaySuccess('Locked');
//   } catch (err) {
//     dispatch(actions.lockMasterplanError(err));
//   }
// };

const savePriorities = () => async (dispatch: DispatchFn, getState: GetStateFn) => {
  dispatch(actions.savePriorities());

  const {
    masterplan: {
      priorities: { date, manualPriorities, medium, trigger },
    },
  } = getState();

  try {
    const payload = { campaignIds: manualPriorities?.map(pr => pr.id) };
    await postPriorities({ date, medium, trigger }, payload);
    dispatch(actions.savePrioritiesSuccess());
    displaySuccess('Saved');
  } catch (err) {
    dispatch(actions.savePrioritiesError(err));
  }
};

const updatePriorities =
  (rawManualPriorities: Array<ICampaignPriority>, systemPriorities: Array<ICampaignPriority>) => (dispatch: DispatchFn) => {
    const manualPriorities = rawManualPriorities.map(({ priority, ...rest }, i) => ({ priority: i + 1, ...rest }));
    dispatch(actions.updatePriorities({ manualPriorities, systemPriorities }));
  };

export {
  getPriorities,
  getSystemPriorities,
  // lockMasterplan,
  savePriorities,
  updatePriorities,
};
