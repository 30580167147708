import * as api from 'app/api/segments2';
import { IQueryParams } from 'app/types/IQueryParams';
import { Action, DispatchFn } from 'app/types/state';

import * as actions from './actions';

export const getAllSegmentsPaged =
  (params: IQueryParams) =>
  async (dispatch: DispatchFn): Promise<Action> => {
    dispatch(actions.getAll());
    try {
      const { first, last, number, totalElements, size, content: segments } = await api.getSegmentsPaged(params);
      return dispatch(
        actions.getAllSuccess(segments, {
          first,
          last,
          number,
          size,
          totalElements,
        }),
      );
    } catch (e) {
      return dispatch(actions.getAllFail(e));
    }
  };
