import { History } from 'history';

import moment from 'moment';
import 'moment-timezone';
import { assoc, compose, dissoc, evolve, path, tap } from 'ramda';

import { stripCarousel } from 'app/api/campaigns/utils';
import { getSubChannel } from 'app/ducks/campaigns/campaigns/mappers';
import { mapIds } from 'app/ducks/helpers';
import { evaluateTemplate } from 'app/ducks/templateManagement/operations';
import { formatDelayTime } from 'app/features/Campaigns/utils';
import { cashbackTypes } from 'app/features/Cashback/constants';
import { IRuleCriterion } from 'app/features/Cashback/types';
import { displayError, displaySuccess } from 'app/helpers/NotificationHelpers/helpers';
import { stringifyPredicates } from 'app/midgarComponents/PredicateBuilder/helper';
import { ICampaign, IEdge, IStage } from 'app/types/Campaign';
import { Action, CampaignsState, DispatchFn, GetStateFn } from 'app/types/state';
import { addEachIdToName } from 'app/utilities/addIdToName';
import { asApiError } from 'app/utilities/errors';
import * as fetch from 'app/utilities/http';
import { bannerMediumId, cashbackMediumId, emailMediumId, notificationMediumId, push2MediumId } from 'configs/channels/mediumIds';

import * as actions from './actions';
import { api } from './api';
import { updateCreativeType } from './cashbackCreative/actions';
import { setCampaign, setDays, setTestDeliveryCreative, transformCampaignVariables } from './helpers';
import { fromApiJourneyCreative } from './mappers';

const setMappedCashbackCreative = (creative: { stages: IStage[]; edges: IEdge[] }, type: string) =>
  type === cashbackTypes.regular || !type
    ? actions.setRegularCashbackCreative(creative)
    : actions.setJourneyCreative(fromApiJourneyCreative(creative));

const mediumActions = {
  [bannerMediumId]: actions.setBannerCreative,
  [cashbackMediumId]: setMappedCashbackCreative,
  [emailMediumId]: actions.setEmailCreative,
  [push2MediumId]: actions.setPush2Creative,
  [notificationMediumId]: actions.setTimelineNotificationCreative,
};

const dispatchMediumAction = (dispatch, mediumId, creative, preserveRawCreative?: boolean) => {
  if (mediumId === cashbackMediumId) {
    dispatch(updateCreativeType({ type: creative.type })); // update cashback creative creative type
    dispatch(mediumActions[mediumId](creative, creative.type));
  } else {
    dispatch(mediumActions[mediumId](creative, preserveRawCreative));
  }
};

const transformCriteriaPredicate = (criteria: { predicateFilterObject: string | IRuleCriterion[] }) => {
  if (!criteria.predicateFilterObject) return;
  return stringifyPredicates(criteria.predicateFilterObject);
};

const setCustomerCount = (scheduling: { customerCountPerExecution: number | string }) => {
  if (scheduling) {
    return typeof scheduling.customerCountPerExecution === 'number'
      ? String(scheduling.customerCountPerExecution)
      : scheduling.customerCountPerExecution;
  }
  return '';
};

export const formatCriteria = criteria => {
  if (criteria?.triggerDelayInSeconds || criteria?.triggerDelayInSeconds === 0) {
    const result = { ...criteria, delayTime: formatDelayTime(criteria.triggerDelayInSeconds) };

    delete result.triggerDelayInSeconds;

    return result;
  }

  return criteria;
};

export const formatCampaign = timezone => campaign => {
  const {
    medium,
    scheduling,
    includedSegments,
    excludedSegments,
    includedSegmentsFilters,
    excludedSegmentsFilters,
    variables,
    criteria,
    ...props
  } = addBannerRealTimeUserCriteriaInCreative(campaign);
  return {
    ...scheduling,
    ...props,
    ...utcToLocal(scheduling, timezone),
    scheduleId: scheduling.id,
    mediumId: props.mediumId || (medium && medium.id),
    subChannel: getSubChannel(campaign),
    customerCountPerExecution: setCustomerCount(scheduling),
    days: setDays(scheduling.cron, scheduling.firstOccurrence, timezone),
    includedSegments: includedSegments ? addEachIdToName(includedSegments) : [],
    excludedSegments: excludedSegments ? addEachIdToName(excludedSegments) : [],
    includedSegmentsFilters: includedSegmentsFilters ? addEachIdToName(includedSegmentsFilters) : [],
    excludedSegmentsFilters: excludedSegmentsFilters ? addEachIdToName(excludedSegmentsFilters) : [],
    audienceFiltersEnabled:
      (Array.isArray(includedSegmentsFilters) && includedSegmentsFilters.length > 0) ||
      (Array.isArray(excludedSegmentsFilters) && excludedSegmentsFilters.length > 0),
    variables: {
      events: Array.isArray(variables) ? variables.filter(variable => variable.source === 'event') : [],
      features: Array.isArray(variables) ? variables.filter(variable => variable.source === 'features') : [],
    },

    criteria: formatCriteria(criteria),
  };
};

/**
 * Add real time user criteria to creative if it is banner campaign
 * @param campaign
 * @returns {*}
 */
const addBannerRealTimeUserCriteriaInCreative = campaign => {
  if (campaign?.medium?.id === bannerMediumId || campaign?.mediumId === bannerMediumId) {
    const { creative, bannerRealTimeUserCriteria } = campaign;
    campaign.creative = {
      ...creative,
      bannerRealTimeUserCriteria: bannerRealTimeUserCriteria || {
        userCriteria: null,
        userCriteriaJson: [],
      },
    };
  }
  return campaign;
};

const setCampaignField =
  field =>
  (dispatch: DispatchFn): Action =>
    dispatch(actions.setCampaignField(field));

const setCampaignStore = (campaign, dispatch, getState, preserveRawCreative?: boolean) => {
  const {
    user: { timezone },
  } = getState();

  const formattedCampaign = formatCampaign(timezone)(addBannerRealTimeUserCriteriaInCreative(campaign));
  const { mediumId, creative } = formattedCampaign;

  if (mediumActions[mediumId]) {
    dispatchMediumAction(dispatch, mediumId, creative, preserveRawCreative);
  }

  dispatch(actions.getSuccess(formattedCampaign));
};

const preserveCampaign = (creativeName: keyof CampaignsState['campaign'], dispatch: DispatchFn, getState: GetStateFn) => {
  const {
    campaigns: { campaign },
  } = getState();

  const creative = campaign.general.mediumId ? campaign[creativeName] : {};
  const originalCampaign = {
    ...JSON.parse(JSON.stringify(campaign.general)),
    creative: JSON.parse(JSON.stringify(creative)),
  };

  dispatch(setCampaignField({ originalCampaign }));
  return addBannerRealTimeUserCriteriaInCreative({
    ...campaign.general,
    originalCampaign,
  });
};

const processAudience = async campaign => {
  try {
    if (campaign?.audienceSource === 'AUDIENCE_PROCESSOR' && campaign.audienceId.length !== 0) {
      await fetch
        .post('/audience/list-metadata', [campaign.audienceId])
        .then(data => {
          const audience = {
            audienceType: data[0]?.audienceType || '',
            type: data[0]?.audienceType || '',
            description: data[0]?.description || '',
            id: data[0]?.audienceId || '',
            name: `${data[0]?.name} [${data[0]?.audienceId}]` || '',
          };

          campaign.audienceId = [audience];
          return campaign;
        })
        .catch(error => {
          campaign.audienceId = [];
          return campaign;
        });
    } else {
      campaign.audienceId = [];
    }
    return campaign;
  } catch (error) {
    return campaign;
  }
};

const getCampaign =
  ({ id }: { id: number }, preserve?: boolean, creativeName?: keyof CampaignsState['campaign']) =>
  async (dispatch: DispatchFn, getState: GetStateFn): Promise<void> => {
    if (!id) {
      return;
    }

    dispatch(actions.get());
    try {
      let campaign = await fetch.get(api.get.replace(':id', String(id)));
      campaign = await processAudience(campaign);
      setCampaignStore(campaign, dispatch, getState);

      if (preserve) {
        preserveCampaign(creativeName, dispatch, getState);
      }
    } catch (e) {
      dispatch(actions.getFail((e as Error).message));
    }
  };

// Function to create(post) and edit/modify(put) a campaign
const handleCampaignRequest =
  ({
    active,
    id,
    method,
    apiEndpoint,
    successCallback,
  }: {
    active: boolean;
    id?: number;
    method: 'post' | 'put';
    apiEndpoint: string;
    successCallback?: (campaign: { id: number }) => void;
  }) =>
  async (dispatch: DispatchFn, getState: GetStateFn): Promise<void> => {
    // presence of ID basically signifies it's an update call
    const campaignRaw = id ? { ...setCampaign(getState), id } : setCampaign(getState);

    // TODO: Strip the carousel field until the back end supports it, then remove this method
    const payload = stripCarousel(campaignRaw as unknown as ICampaign);

    dispatch(actions.post());
    try {
      payload.variables = transformCampaignVariables(payload);

      if (payload?.criteria?.predicateFilterObject) {
        payload.criteria.predicateFilter = transformCriteriaPredicate(payload.criteria);
      }

      if (payload?.criteria?.deactivationEvent?.predicateFilterObject) {
        payload.criteria.deactivationEvent.predicateFilter = transformCriteriaPredicate(payload.criteria.deactivationEvent);
      }

      const audienceIdV2 = mapIds(payload.audienceId);
      const storedAudienceObj = payload.audienceId;
      payload.audienceId = audienceIdV2.length > 0 && !payload.useEveryoneAudience ? audienceIdV2[0] : undefined;
      payload.audienceSource = audienceIdV2.length > 0 && !payload.useEveryoneAudience ? 'AUDIENCE_PROCESSOR' : 'RULE_ENGINE';

      let campaign;
      if (method === 'post') {
        campaign = await fetch.post(`${apiEndpoint}${active ? '?activateImmediately=true' : ''}`, payload);
      } else if (method === 'put') {
        campaign = await fetch.put(apiEndpoint.replace(':id', String(id)), payload);
      }

      campaign.audienceId = storedAudienceObj;
      setCampaignStore(campaign, dispatch, getState, method === 'post');

      // Activate campaign if required post edit
      if (active && method === 'put') {
        campaign = await fetch.post(api.activate.replace(':id', String(id)));
        campaign.audienceId = storedAudienceObj;
        setCampaignStore(campaign, dispatch, getState);
      }

      displaySuccess(active ? 'Campaign successfully created' : 'Campaign successfully saved');
      dispatch(actions.postSuccess(campaign.id));

      if (successCallback) {
        successCallback(campaign);
      }
    } catch (e) {
      const error = JSON.parse((e as Error).message);
      displayError(`Error processing campaign: ${error.message || e}`);
      return dispatch(actions.postFail(error.message || e));
    }
  };

const postCampaign = ({ history, active }: { history: History; active: boolean }) => {
  return handleCampaignRequest({
    active,
    method: 'post',
    apiEndpoint: api.post,
    successCallback: campaign => {
      history.push(`/campaigns/${campaign.id}`, { preserveRawCreative: active });
    },
  });
};

const updateCampaign = ({ history, active, id }: { history: History; active: boolean; id: number }) => {
  return handleCampaignRequest({
    active,
    id,
    method: 'put',
    apiEndpoint: api.update,
    successCallback: campaign => {
      history.push(`/campaigns/${campaign.id}`);
    },
  });
};

const activateCampaign =
  ({ id }) =>
  async (dispatch: DispatchFn, getState: GetStateFn) => {
    dispatch(actions.post());
    try {
      const campaignRaw = setCampaign(getState);
      const storedAudienceObj = campaignRaw.audienceId;

      const campaign = await fetch.post(api.activate.replace(':id', id));
      campaign.audienceId = storedAudienceObj;
      setCampaignStore(campaign, dispatch, getState);
      dispatch(actions.postSuccess(id));
      displaySuccess('Campaign successfully approved');
    } catch (e) {
      const { message, details } = e;
      displayError(`Error saving campaign: ${message || details}`);
      return dispatch(actions.postFail(e.message));
    }
  };

const newCampaign =
  () =>
  (dispatch: DispatchFn): Action =>
    dispatch(actions.newCampaign());

const setCampaignFieldPromise =
  (payload: object) =>
  (dispatch: DispatchFn): Promise<void> => {
    return new Promise(resolve => {
      dispatch(setCampaignField(payload));
      return resolve();
    });
  };

const setCriteria =
  obj =>
  (dispatch: DispatchFn): Action =>
    dispatch(actions.setCriteria(obj));

const removeIncludeSegment = segment => (dispatch: DispatchFn) => {
  dispatch(actions.removeIncludeSegment(segment));
};

const removeExcludeSegment = segment => (dispatch: DispatchFn) => {
  dispatch(actions.removeExcludeSegment(segment));
};

const addTag = tag => (dispatch: DispatchFn) => dispatch(actions.addTag(tag));

const removeTag = tag => (dispatch: DispatchFn) => dispatch(actions.removeTag(tag));

const getCampaignSize =
  ({ type, id }) =>
  async (dispatch: DispatchFn, getState: GetStateFn): Promise<Action> => {
    dispatch(actions.getCampaignSize());

    try {
      if (type === 'edit' || type === 'new') {
        const campaign = { ...setCampaign(getState), mediumId: 1 };
        campaign.variables = [...campaign.variables.features, ...campaign.variables.events];

        if (campaign.includedSegmentIds.length > 0) {
          const size = await fetch.post(api.postCampaignSize, campaign);
          return dispatch(actions.getCampaignSizeSuccess(size));
        }
        return dispatch(actions.getCampaignSizeSuccess(0));
      }
      const size = await fetch.get(api.getCampaignSize.replace(':id', id));
      return dispatch(actions.getCampaignSizeSuccess(size));
    } catch (err) {
      return dispatch(actions.getCampaignSizeFail());
    }
  };

const splitTimes = (time, timezone) => moment.tz(time, 'Etc/UTC').clone().tz(timezone).format('YYYY-MM-DD[T]HH:mm').split('T');

export const utcToLocal = (scheduling, timezone) => {
  if (scheduling) {
    const [startDate, startTime] = scheduling.firstOccurrence ? splitTimes(scheduling.firstOccurrence, timezone) : [undefined, undefined];
    const [expiryDate, expiryTime] = scheduling.expiryDate ? splitTimes(scheduling.expiryDate, timezone) : [undefined, undefined];
    return { startDate, startTime, expiryDate, expiryTime };
  }
  return {};
};

const appendClone = s => `${s}_CLONE`;

const setCreative = ({ dispatch }) =>
  tap(({ creative, mediumId }) => mediumActions[mediumId] && dispatchMediumAction(dispatch, mediumId, creative));

const removeFields = compose(dissoc('createdBy'), dissoc('createdAt'), dissoc('updatedBy'), dissoc('updatedAt'), dissoc('state'));

const cloneCampaign =
  ({ id }, creativeName: keyof CampaignsState['campaign']) =>
  async (dispatch: DispatchFn, getState: GetStateFn): Promise<Action> => {
    const {
      campaigns: {
        campaign: { general: campaignInRedux },
      },
    } = getState();

    if (+campaignInRedux.id !== +id) {
      dispatch(actions.get());
      try {
        let campaign = await fetch.get(api.get.replace(':id', id));
        campaign = await processAudience(campaign);
        setCampaignStore(campaign, dispatch, getState);
      } catch (e) {
        return dispatch(actions.getFail(e.message));
      }
    }

    return compose(
      dispatch,
      actions.getSuccess,
      assoc('clone', true),
      evolve({ name: appendClone }),
      removeFields,
      () => preserveCampaign(creativeName, dispatch, getState),
      ({ creative, mediumId, ...rest }) =>
        mediumId === cashbackMediumId && creative.type === cashbackTypes.journey
          ? setCreative({ dispatch })({ creative: { ...creative, cashbackPromoBanner: null }, mediumId, ...rest }) // set cashbackPromoBanner to null bc only one banner could be associated with one campaign
          : setCreative({ dispatch })({ creative, mediumId, ...rest }),
      path(['campaigns', 'campaign', 'general']),
      getState,
    )();
  };

const addVariable = variable => (dispatch: DispatchFn, getState: GetStateFn) => {
  const {
    campaigns: {
      campaign: {
        general: { variables },
      },
    },
  } = getState();

  if (variable.source === 'event' && !variables.events.find(v => v.name === variable.name)) dispatch(actions.addEventsVariable(variable));
  else if (variable.source !== 'event' && !variables.features.find(v => Math.abs(v.id) === variable.id)) {
    dispatch(actions.addFeaturesVariable(variable));
  }
};

const updateVariable =
  variable =>
  (dispatch: DispatchFn): Action =>
    variable.source && variable.source === 'event'
      ? dispatch(actions.updateEventsVariables(variable))
      : dispatch(actions.updateFeaturesVariables(variable));

const removeVariable =
  variable =>
  (dispatch: DispatchFn): Action =>
    variable.source && variable.source === 'event'
      ? dispatch(actions.removeEventsVariable(variable))
      : dispatch(actions.removeFeaturesVariable(variable));

const removeAllVariables =
  () =>
  (dispatch: DispatchFn): Action =>
    dispatch(actions.removeAllVariables());

const testDelivery = recipients => async (dispatch: DispatchFn, getState: GetStateFn) => {
  const campaign = { ...setCampaign(getState) };
  campaign.variables = transformCampaignVariables(campaign);
  const audienceIdV2 = mapIds(campaign.audienceId);
  campaign.audienceId = audienceIdV2.length > 0 ? audienceIdV2[0] : null;
  const parsedRecipients = recipients;
  let evaluatedTemplate = {};

  try {
    if (campaign.creative.templated) {
      evaluatedTemplate = await dispatch(evaluateTemplate());
    }

    const strippedCarouselCampaign = stripCarousel(campaign);

    // TODO: Call api/campaigns/testDelivery.js
    await fetch.post(api.testDelivery, {
      campaign: {
        id: -1,
        ...strippedCarouselCampaign,
        creative: setTestDeliveryCreative(strippedCarouselCampaign, evaluatedTemplate),
      },

      targetUsers: parsedRecipients,
      version: 2,
    });

    displaySuccess('Test messages successfully sent');
  } catch (e) {
    displayError('Error sending test messages');
  }
};

const notificationDelivery = recipients => async (dispatch: DispatchFn, getState: GetStateFn) => {
  const campaign = { ...setCampaign(getState) };
  campaign.variables = transformCampaignVariables(campaign);
  const parsedRecipients = recipients;
  let evaluatedTemplate = {};

  try {
    if (campaign.creative.templated) {
      evaluatedTemplate = await dispatch(evaluateTemplate());
    }

    const strippedCarouselCampaign = stripCarousel(campaign);

    // TODO: Call api/campaigns/notificationDelivery.js
    await fetch.post(api.notificationDelivery, {
      creative: setTestDeliveryCreative(strippedCarouselCampaign, evaluatedTemplate),
      users: parsedRecipients,
    });

    displaySuccess('Test messages successfully sent');
  } catch (e) {
    displayError('Error sending test messages');
  }
};

const setStatus =
  ({ id, status }: { id: number; status: string }) =>
  async (dispatch: DispatchFn) => {
    try {
      await fetch.put(api.setState.replace(':id', String(id)), status, 'string');
      dispatch(actions.setStatus({ status, id }));
    } catch (err) {
      const apiError = asApiError(err);
      const msg = 'Error while changing campaign state';
      displayError(msg);
      console.error(msg, apiError);
    }
  };

const getMetrics =
  (id: number) =>
  async (dispatch: DispatchFn): Promise<Action> => {
    try {
      const metrics = await fetch.get(api.getMetrics.replace(':id', id));
      // todo: add Mapping func
      return dispatch(actions.getMetricsSuccess(metrics));
    } catch (e) {
      return dispatch(actions.getMetricsFail(e));
    }
  };

export {
  activateCampaign,
  postCampaign,
  newCampaign,
  setCampaignField,
  setCriteria,
  removeIncludeSegment,
  removeExcludeSegment,
  getCampaignSize,
  updateCampaign,
  getCampaign,
  cloneCampaign,
  updateVariable,
  addVariable,
  removeVariable,
  removeAllVariables,
  testDelivery,
  notificationDelivery,
  setStatus,
  addTag,
  removeTag,
  getMetrics,
  setCampaignFieldPromise,
};
