import moment from 'moment';
import { type Moment } from 'moment';

import { IRuleCriterion } from 'app/features/Cashback/types';
import { isNilOrEmpty } from 'app/helpers/RamdaHelpers/helpers';

const WEEK_DAYS_MAPPING: Record<string, number> = {
  SUN: 0,
  MON: 1,
  TUE: 2,
  WED: 3,
  THU: 4,
  FRI: 5,
  SAT: 6,
};

const AGO_UNITS_MAPPING = {
  days: 'd',
  hours: 'h',
  minutes: 'm',
};

// TODO - need to refactor this convert into more robust class with properties and methods.
export const NEW_EMPTY_CRITERIA = {
  fieldName: '',
  operator: '',
  ruleValues: '',
  conjuctor: '',
  fieldType: '',
};

export const CONJUCTION_BUTTONS = [
  {
    id: 'and',
    label: 'AND',
  },

  {
    id: 'or',
    label: 'OR',
  },
];

export const FIELD_TYPES = {
  string: 'String',
  number: 'Number',
  dateTime: 'DateTime',
  boolean: 'Boolean',
};

export const ONE_SPACE = ' ';
export const JAPAN_TIMEZONE_OFFSET_STR = '+09:00';
export const AGO = 'ago';
export const LAST = 'Last';
export const BETWEEN_TIME_OF_DAY = 'betweentimeofday';
export const ON_DAY_OF_WEEK = 'ondayofweek';
export const FORMAT = 'HH:mm';
export const DATE_TIME_OPERATORS = ['>', '<', '=', '>=', '<='];
// TODO remove this hack
export const TIME_ZONE = 'Asia/Tokyo';
export const arrayOperators = ['in', 'notin', 'any', 'none'];
export const EQUALS_TO_FIELD = 'EqualsToField';

export const momentify = (str: Moment): Moment => {
  if (!str) return moment();
  return str._isAMomentObject ? str : moment(str); /* eslint no-underscore-dangle: 0 */
};

export const isInvalidValue = (predicate: IRuleCriterion) => {
  const { ruleValues, fieldType, operator, fieldName } = predicate ?? {};

  if ([predicate, ruleValues, fieldType, operator].some(isNilOrEmpty)) return true;

  if (fieldType === FIELD_TYPES.dateTime) {
    if (operator === AGO || operator === LAST) {
      const { unitValue, unit } = ruleValues;
      return !unitValue || isNilOrEmpty(unit);
    }
    if (operator === BETWEEN_TIME_OF_DAY) {
      const { from, to } = ruleValues;
      return isNilOrEmpty(from) || isNilOrEmpty(to) || !momentify(from).isBefore(momentify(to));
    }
  }

  if (fieldType === FIELD_TYPES.string) {
    if (fieldName === 'storeId') {
      const midGroup = '0-9';
      const sidMSidGroup = '一-龠ぁ-ゔァ-ヴーa-zA-Z0-9ａ-ｚＡ-Ｚ０-９々〆〤_';
      if (arrayOperators.includes(operator.toLowerCase())) {
        // verify if storeId is in 'MID-SID' or 'MID-MSID' and comma separated format
        const regex = new RegExp(`^[${midGroup}]+-[${sidMSidGroup}]+(, ?[${midGroup}]+-[${sidMSidGroup}]+)*$`);
        return !regex.test(ruleValues);
      }

      // verify if storeId is in 'MID-SID' or 'MID-MSID' format
      const regex = new RegExp(`^[${midGroup}]+-[${sidMSidGroup}]+$`);
      return !regex.test(ruleValues);
    }

    return !/^[^"`']+$/.test(ruleValues);
  }

  return false;
};

export const isValid = (predicates: Array<IRuleCriterion> | string) => {
  if (typeof predicates === 'string') {
    return true;
  }
  return predicates && predicates.length > 0 ? !predicates.some(isInvalidValue) : true;
};

/**
 * Checks if all predicates are empty or valid.
 * A predicate is considered empty if its `ruleValues` is an empty string or undefined.
 * A predicate is considered valid if it fails the `isInvalidValue` check.
 *
 * @param predicates - Array of IRuleCriterion objects to check.
 * @returns True if all predicates are empty or valid, false otherwise.
 */
export const isEmptyOrValidPredicate = (predicates: Array<IRuleCriterion>) => {
  return predicates.every(predicate => predicate.ruleValues === '' || predicate.ruleValues === undefined || !isInvalidValue(predicate));
};

const fomatDateTimeValue = (ruleValues: Record<string, any>, operator: string) => {
  if (operator === ON_DAY_OF_WEEK) {
    const result = `([${ruleValues
      .split(',')
      .map(val => WEEK_DAYS_MAPPING[val])
      .join(',')}],${ONE_SPACE}${JAPAN_TIMEZONE_OFFSET_STR})`;
    return result;
  }
  if (operator === AGO || operator === LAST) {
    const { unitValue, unit } = ruleValues;
    const temp = moment.duration(parseInt(unitValue, 10), AGO_UNITS_MAPPING[unit]);
    const result = `(${temp.asMilliseconds()},${ONE_SPACE}${JAPAN_TIMEZONE_OFFSET_STR})`;
    return result;
  }
  if (operator === BETWEEN_TIME_OF_DAY) {
    const { from, to } = ruleValues;
    const result = `(${momentify(from).format(FORMAT)}${JAPAN_TIMEZONE_OFFSET_STR},${momentify(to).format(
      FORMAT,
    )}${JAPAN_TIMEZONE_OFFSET_STR})`;
    return result;
  }
  return `${ONE_SPACE}${momentify(ruleValues).valueOf()}`; // send UTC time back, ruleValues is already UTC
};

const prettifyDateTimeValue = (ruleValues: Record<string, any>, operator) => {
  switch (operator) {
    case LAST: {
      const { unitValue, unit } = ruleValues;
      return `last ${unitValue} ${unit}`;
    }
    case AGO: {
      const { unitValue, unit } = ruleValues;
      return `${unitValue} ${unit} ago`;
    }
    case ON_DAY_OF_WEEK:
      return `on the following day of the week: ${ruleValues}`;
    case BETWEEN_TIME_OF_DAY: {
      const { from, to } = ruleValues;
      return `between time of day: ${momentify(from).format(FORMAT)}, ${momentify(to).format(FORMAT)}`;
    }
    default:
      return `${operator} ${momentify(ruleValues).tz(TIME_ZONE).format('YYYY/MM/DD HH:mm:ss')}`;
  }
};

const formatPredicateRuleValue = ({ ruleValues, fieldType, operator }: IRuleCriterion) => {
  if (fieldType === FIELD_TYPES.dateTime) {
    return fomatDateTimeValue(ruleValues, operator);
  }
  const needsQuote = ['string', 'stringset'].includes(fieldType.toLowerCase());
  const isSet = fieldType.toLowerCase() === 'stringset' || (operator && arrayOperators.includes(operator.toLowerCase()));

  if (isSet) {
    const vals = (ruleValues || '')
      .split(',')
      .filter(x => x.trim() !== '')
      .map(s => (needsQuote ? `'${s ? s.trim() : s}'` : s));
    return `${ONE_SPACE}[${vals.join(',')}]`;
  }

  return needsQuote ? `${ONE_SPACE}'${ruleValues || ''}'` : `${ONE_SPACE}${ruleValues || ''}`;
};

export const countPredicateRuleValue = (predicate: IRuleCriterion) => {
  return !isInvalidValue(predicate) && ['IN', 'NOTIN', 'any', 'NONE'].includes(predicate.operator)
    ? predicate.ruleValues?.split(',').length
    : '';
};

const stringifyConjuctor = (conj: (string | { label: string }) | null | undefined): string => {
  if (conj === null || conj === undefined) {
    return '';
  }

  const str = typeof conj === 'string' ? conj.trim() : conj.label.trim();
  return str ? `${ONE_SPACE}${str}` : '';
};

export const stringifyPredicate = (predicate: IRuleCriterion) =>
  isInvalidValue(predicate)
    ? ''
    : `${stringifyConjuctor(predicate.conjuctor)} '${predicate.fieldName}' ${predicate.operator}${formatPredicateRuleValue(predicate)}`;

export const stringifyPredicates = (predicates: Array<IRuleCriterion> | string, delimit?: string) => {
  if (typeof predicates === 'string') return predicates;
  if (!predicates || predicates.length === 0) return '';
  const result =
    (predicates || [])
      .map(stringifyPredicate)
      .join(delimit || '')
      .trim() || '';
  return result;
};

export const prettifyPredicate = (predicate: IRuleCriterion) =>
  isInvalidValue(predicate)
    ? ''
    : `${stringifyConjuctor(predicate.conjuctor)} ${predicate.fieldName} ${
        predicate.fieldType === FIELD_TYPES.dateTime
          ? prettifyDateTimeValue(predicate.ruleValues, predicate.operator)
          : `${predicate.operator}${formatPredicateRuleValue(predicate)}`
      }`;

export const prettifyPredicates = (predicates: Array<IRuleCriterion> | string, delimit?: string) => {
  if (typeof predicates === 'string') return predicates;
  if (!predicates || predicates.length === 0) return null;
  const result =
    (predicates || [])
      .map(prettifyPredicate)
      .join(delimit || '')
      .trim() || null;
  return result;
};

const invalidCriteriaCombinations = {
  merchantId: {
    IN: {
      merchantId: ['IN', '=', 'NOTIN', 'any'],
      storeId: ['IN', '=', 'any'],
    },

    '=': {
      merchantId: ['IN', '=', 'NOTIN', 'any'],
      storeId: ['IN', '=', 'any'],
    },

    NOTIN: {
      merchantId: ['IN', '=', 'NOTIN', 'any'],
      storeId: ['IN', '=', 'any'],
    },
  },

  storeId: {
    IN: {
      merchantId: ['IN', '=', 'NOTIN', 'any'],
      storeId: ['IN', '=', 'NOTIN', 'any'],
    },

    '=': {
      merchantId: ['IN', '=', 'NOTIN', 'any'],
      storeId: ['IN', '=', 'NOTIN', 'any'],
    },

    NOTIN: {
      merchantId: ['any'],
      storeId: ['IN', '=', 'NOTIN', 'any'],
    },
  },
};

export const validCriteriaCombinations = (predicates: Array<IRuleCriterion> | string) => {
  if (!Array.isArray(predicates)) return true;

  let result = true;

  predicates.forEach(({ id, fieldName, conjuctor, operator }) => {
    if (invalidCriteriaCombinations[fieldName] && operator === 'any') {
      result = false;
    }

    if (
      conjuctor?.id !== 'or' &&
      invalidCriteriaCombinations[fieldName] &&
      invalidCriteriaCombinations[fieldName][operator] &&
      predicates.some(
        item =>
          id !== item.id &&
          item.conjuctor?.id !== 'or' &&
          invalidCriteriaCombinations[fieldName][operator][item.fieldName]?.includes(item.operator),
      )
    ) {
      result = false;
    }
  });

  return result;
};
