import { stringify } from 'query-string';

import { IQueryParams } from 'app/types/IQueryParams';
import { get, put } from 'app/utilities/http';

import endpoints from './endpoints';

export const downloadSegment = (id: string) => get(endpoints.downloadSegment.replace(':id', id));

export const getSegmentsPaged = (params: IQueryParams) => get(endpoints.getSegmentsPaged.replace(':params', stringify(params)));

export const markForExtraction = (audienceId: string) => put(endpoints.markForExtraction, { audienceId });
